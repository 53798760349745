import { convertToRoleValueList, READ, RolePermissions, AllRoles } from "src/utils/route-guard/permissions";

// TODO: Update with actual permissions and roles allowed to view infection control

// INFECTION CONTROL
const infectionControl = {
  SUPER_ADMIN: AllRoles.SUPER_ADMIN,
  INFECTION_CONTROL_EDITOR: AllRoles.INFECTION_CONTROL_EDITOR,
};

type InfectionControlRoles = typeof infectionControl;
type InfectionControlUi = Record<string, never>;

export const InfectionControlPermissions: RolePermissions<InfectionControlRoles, InfectionControlUi> = {
  canVisit: convertToRoleValueList(infectionControl),
  ui: {},
  permissions: {
    SUPER_ADMIN: READ,
    INFECTION_CONTROL_EDITOR: READ,
  },
};
