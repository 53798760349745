import {
  convertToRoleValueList,
  CRUD,
  READ,
  AllRoles,
  RolePermissions,
  Roles,
} from "src/utils/route-guard/permissions";

// WAREHOUSE LIST
const warehouseList = {
  SUPER_ADMIN: AllRoles.SUPER_ADMIN,
  PRODUCT_EDITOR: AllRoles.PRODUCT_EDITOR,
  PRODUCT_ADMIN: AllRoles.PRODUCT_ADMIN,
  LOT_EDITOR: AllRoles.LOT_EDITOR,
  LOT_PREP_EDITOR: AllRoles.LOT_PREP_EDITOR,
  MANUFACTURING_USER: AllRoles.MANUFACTURING_USER,
  MANUFACTURING_EDITOR: AllRoles.MANUFACTURING_EDITOR,
  MANUFACTURING_ADMIN: AllRoles.MANUFACTURING_ADMIN,
  WAREHOUSE_EDITOR: AllRoles.WAREHOUSE_EDITOR,
  CUSTOMER_SUPPORT: AllRoles.CUSTOMER_SUPPORT,
  USER: AllRoles.USER,
};

type WarehouseListRoles = typeof warehouseList;
interface WarehouseListUi {
  canSeeStockProductButton: Roles;
}
export const WarehouseListPermissions: RolePermissions<WarehouseListRoles, WarehouseListUi> = {
  canVisit: convertToRoleValueList(warehouseList),
  ui: { canSeeStockProductButton: [AllRoles.SUPER_ADMIN, AllRoles.MANUFACTURING_ADMIN, AllRoles.WAREHOUSE_EDITOR] },
  permissions: {
    SUPER_ADMIN: CRUD,
    PRODUCT_EDITOR: READ,
    PRODUCT_ADMIN: READ,
    LOT_EDITOR: READ,
    LOT_PREP_EDITOR: READ,
    MANUFACTURING_USER: READ,
    MANUFACTURING_EDITOR: READ,
    MANUFACTURING_ADMIN: CRUD,
    WAREHOUSE_EDITOR: READ,
    CUSTOMER_SUPPORT: READ,
    USER: READ,
  },
};

// WAREHOUSE STOCK PRODUCT
export const warehouseStockProduct = {
  SUPER_ADMIN: AllRoles.SUPER_ADMIN,
  WAREHOUSE_EDITOR: AllRoles.WAREHOUSE_EDITOR,
  MANUFACTURING_ADMIN: AllRoles.MANUFACTURING_ADMIN,
};

type WarehouseStockProductRoles = typeof warehouseStockProduct;
type WarehouseStockProductUi = Record<string, never>;

export const WarehouseStockProductPermissions: RolePermissions<WarehouseStockProductRoles, WarehouseStockProductUi> = {
  canVisit: convertToRoleValueList(warehouseStockProduct),
  ui: {},
  permissions: { SUPER_ADMIN: CRUD, WAREHOUSE_EDITOR: CRUD, MANUFACTURING_ADMIN: CRUD },
};

// WAREHOUSE DETAIL STOCK
export const warehouseDetail = {
  SUPER_ADMIN: AllRoles.SUPER_ADMIN,
  PRODUCT_EDITOR: AllRoles.PRODUCT_EDITOR,
  PRODUCT_ADMIN: AllRoles.PRODUCT_ADMIN,
  LOT_EDITOR: AllRoles.LOT_EDITOR,
  LOT_PREP_EDITOR: AllRoles.LOT_PREP_EDITOR,
  MANUFACTURING_USER: AllRoles.MANUFACTURING_USER,
  MANUFACTURING_EDITOR: AllRoles.MANUFACTURING_EDITOR,
  MANUFACTURING_ADMIN: AllRoles.MANUFACTURING_ADMIN,
  WAREHOUSE_EDITOR: AllRoles.WAREHOUSE_EDITOR,
  CUSTOMER_SUPPORT: AllRoles.CUSTOMER_SUPPORT,
  USER: AllRoles.USER,
};

type WarehouseDetailRoles = typeof warehouseDetail;
type WarehouseDetailUi = Record<string, never>;

export const WarehouseDetailPermissions: RolePermissions<WarehouseDetailRoles, WarehouseDetailUi> = {
  canVisit: convertToRoleValueList(warehouseDetail),
  ui: {},
  permissions: {
    SUPER_ADMIN: READ,
    PRODUCT_EDITOR: READ,
    PRODUCT_ADMIN: READ,
    LOT_EDITOR: READ,
    LOT_PREP_EDITOR: READ,
    MANUFACTURING_USER: READ,
    MANUFACTURING_EDITOR: READ,
    MANUFACTURING_ADMIN: READ,
    WAREHOUSE_EDITOR: READ,
    CUSTOMER_SUPPORT: READ,
    USER: READ,
  },
};
