// Imports
import React, { FC, Suspense, useEffect, useState } from "react";
import getConfig from "../config/config";
const config = getConfig();
import { render } from "react-dom";
import { ThemeProvider } from "@mui/material/styles";
import { HashRouter, Route, Routes } from "react-router-dom";
import Box from "@mui/material/Box";
import { loginRequest, msalConfig } from "src/api/activedirectory/authConfig";
import { PublicClientApplication } from "@azure/msal-browser";
import { useMsal, AuthenticatedTemplate, UnauthenticatedTemplate, MsalProvider } from "@azure/msal-react";
import { acquireToken, IIdTokenClaims, useGetAccount } from "src/utils/auth";
import DCButton from "dc-ui/components/DCButton/DCButton";
import { RouteGuard } from "src/utils/route-guard/RouteGuard";
import {
  ProductListPermissions,
  ProductDetailPermissions,
  ProductDetailEditPermissions,
  ProductDetailCreatePermissions,
} from "src/utils/route-guard/permissions/productPermissions";
import {
  LotManagementListPermissions,
  LotManagementDetailPermissions,
  LotManagementEditPermissions,
  LotManagementCreatePermissions,
} from "src/utils/route-guard/permissions/lotManagementPermissions";
import {
  LotProductionBoxStationPermissions,
  LotProductionDetailPermissions,
  LotProductionLabelStationPermissions,
  LotProductionListPermissions,
} from "src/utils/route-guard/permissions/lotProductionPermissions";
import { UdiListPermissions } from "src/utils/route-guard/permissions/udiPermissions";
import { InfectionControlPermissions } from "src/utils/route-guard/permissions/infectionPermissions";
import {
  WarehouseListPermissions,
  WarehouseStockProductPermissions,
  WarehouseDetailPermissions,
} from "src/utils/route-guard/permissions/warehousePermissions";

import { AllRoles, Roles } from "src/utils/route-guard/permissions";
import { routes } from "src/utils/routes";

/*
{
    typePolicies: {
      Query: {
        fields: {
          getProducts: {},
        },
      },
    },
  }
*/
//? Azure Active Directory - Authentication
export const msalInstance = new PublicClientApplication(msalConfig);

//?/ Components
//* Static Loaded
import DCAppBar from "dc-ui/components/DCAppBar/DCAppBar";

//?/ Code
//* Static Loaded
import { themeOptions, themeOptionsDev } from "./utils/stylesheet";

//?/ Pages
//* Static Loaded
import Loading from "./utils/Loading";
import SideBar from "./pages/SideBar";

//* Dynamic loaded
const Home = React.lazy(() => import("./pages/Home"));
//const LotListView = React.lazy(() => import("./pages/lot/ListView"));
const ProductListViewContainer = React.lazy(() => import("./pages/product/ListView.container"));
const ProductDetailContainer = React.lazy(() => import("./pages/product/DetailView/ProductDetail.view.container"));
const ProductDetailEditContainer = React.lazy(() => import("./pages/product/DetailView/ProductDetail.edit.container"));
const ProductDetailCreateContainer = React.lazy(
  () => import("./pages/product/DetailView/ProductDetail.create.container"),
);

const LotListViewContainer = React.lazy(() => import("./pages/lot/management/ListView.container"));
const LotDetailContainer = React.lazy(() => import("./pages/lot/management/DetailViews/LotDetail.view.container"));
const LotDetailEditContainer = React.lazy(() => import("./pages/lot/management/DetailViews/LotDetail.edit.container"));
const LotDetailCreateContainer = React.lazy(
  () => import("./pages/lot/management/DetailViews/LotDetail.create.container"),
);

const LotProdListViewContainer = React.lazy(() => import("./pages/lot/production/ListView.container"));
const LotProdDetailContainer = React.lazy(() => import("./pages/lot/production/DetailView/LotDetail.view.container"));
const ProduceLot = React.lazy(() => import("./pages/lot/production/DetailView/produce/BoxStation.container"));
const ProduceBox = React.lazy(() => import("./pages/lot/production/DetailView/produce/LabelStation.container"));

const UDIListViewContainer = React.lazy(() => import("./pages/udi/UDIView.container"));
const UDIDetailView = React.lazy(() => import("./pages/udi/DetailView/UDIDetail.view.container"));

const WarehouseStartContainer = React.lazy(() => import("./pages/warehouse/WarehouseStartView.container"));
const WarehouseListContainer = React.lazy(() => import("./pages/warehouse/WarehouseListView.container"));
const WarehouseAddStockContainer = React.lazy(() => import("./pages/warehouse/WarehouseAddStockView.container"));
const WarehouseDetailContainer = React.lazy(() => import("./pages/warehouse/WarehouseDetailView.container"));
// const InvoiceListViewContainer = React.lazy(() => import("./pages/invoice/InvoiceListView.container"));
// const InvoiceCreateContainer = React.lazy(() => import("./pages/invoice/CreateView/Invoice.create.container"));

const InfectionControlContainer = React.lazy(() => import("./pages/infection/InfectionControl.container"));

const UnderDevelopment = React.lazy(() => import("./pages/underDev"));
const Prohibited = React.lazy(() => import("./pages/Prohibited"));
import { QueryClient, QueryClientProvider } from "react-query";

const Auth: FC = (props: any) => {
  return <MsalProvider instance={msalInstance}>{props.children}</MsalProvider>;
};

const Main: FC = () => {
  const msal = useMsal();
  const account = useGetAccount();
  const [userRoles, setUserRoles] = useState<Roles>([]);
  const [queryClient] = useState(new QueryClient());

  //const { instance, accounts, inProgress } = useMsal();
  //const isAuthenticated = useIsAuthenticated();
  //const tokenAcquired = useRefreshToken();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    msal.instance.loginRedirect(loginRequest).catch((e) => {
      console.log(e);
    });
  };

  useEffect(() => {
    acquireToken(msal).then((success) => {
      console.log("Initial token acquired: ", success);
    });
    console.info("App Version", process.env.TAG_VERSION);
  }, []);

  useEffect(() => {
    if (account !== null) {
      if (config.setRoleAsDev) {
        setUserRoles([AllRoles.DEV]);
      } else {
        setUserRoles((account?.idTokenClaims as IIdTokenClaims).groups);
      }
      console.info("App Version", process.env.TAG_VERSION);
    }
  }, [account]);

  const isProduction = process.env.PRODUCTION === "true";

  return (
    <ThemeProvider theme={isProduction ? themeOptions : themeOptionsDev}>
      <HashRouter>
        <DCAppBar
          title={`${isProduction ? "Dynamic Code Hub" : "Hub Development Environment"} - ${account?.username}`}
          onClick={() => msal.instance.logoutRedirect()}
        />
        {
          //We should leverage Suspense as much as possible
        }
        <AuthenticatedTemplate>
          <SideBar userRoles={userRoles} />
          <Box sx={{ height: "100vh", width: "100vw", display: "flex" }}>
            <Box
              component="main"
              sx={{
                display: "flex",
                ml: "64px",
                mt: "72px",
                flexGrow: 1,
                px: "32px",
                pb: "48px",
              }}
            >
              <Suspense fallback={<Loading message={"Page"} />}>
                <QueryClientProvider client={queryClient} contextSharing={true}>
                  <Routes>
                    <Route path={routes.home.url} element={<Home userRoles={userRoles} />} />

                    {/** Product */}
                    <Route
                      path={routes.product.list.url}
                      element={
                        <RouteGuard groupAllowedToView={ProductListPermissions.canVisit} userRoles={userRoles}>
                          <ProductListViewContainer userRoles={userRoles} />
                        </RouteGuard>
                      }
                    />
                    <Route
                      path={routes.product.detail.url}
                      element={
                        <RouteGuard groupAllowedToView={ProductDetailPermissions.canVisit} userRoles={userRoles}>
                          <ProductDetailContainer userRoles={userRoles} />
                        </RouteGuard>
                      }
                    />
                    <Route
                      path={routes.product.edit.url}
                      element={
                        <RouteGuard groupAllowedToView={ProductDetailEditPermissions.canVisit} userRoles={userRoles}>
                          <ProductDetailEditContainer userRoles={userRoles} />
                        </RouteGuard>
                      }
                    />
                    <Route
                      path={routes.product.create.url}
                      element={
                        <RouteGuard groupAllowedToView={ProductDetailCreatePermissions.canVisit} userRoles={userRoles}>
                          <ProductDetailCreateContainer userRoles={userRoles} />
                        </RouteGuard>
                      }
                    />

                    {/** Lot management*/}
                    <Route
                      path={routes.lot.management.list.url}
                      element={
                        <RouteGuard groupAllowedToView={LotManagementListPermissions.canVisit} userRoles={userRoles}>
                          <LotListViewContainer userRoles={userRoles} />
                        </RouteGuard>
                      }
                    />
                    <Route
                      path={routes.lot.management.detail.url}
                      element={
                        <RouteGuard groupAllowedToView={LotManagementDetailPermissions.canVisit} userRoles={userRoles}>
                          <LotDetailContainer userRoles={userRoles} />
                        </RouteGuard>
                      }
                    />
                    <Route
                      path={routes.lot.management.edit.url}
                      element={
                        <RouteGuard groupAllowedToView={LotManagementEditPermissions.canVisit} userRoles={userRoles}>
                          <LotDetailEditContainer userRoles={userRoles} />
                        </RouteGuard>
                      }
                    />
                    <Route
                      path={routes.lot.management.create.url}
                      element={
                        <RouteGuard groupAllowedToView={LotManagementCreatePermissions.canVisit} userRoles={userRoles}>
                          <LotDetailCreateContainer userRoles={userRoles} />
                        </RouteGuard>
                      }
                    />

                    {/** Lot production */}
                    <Route
                      path={routes.lot.production.list.url}
                      element={
                        <RouteGuard groupAllowedToView={LotProductionListPermissions.canVisit} userRoles={userRoles}>
                          <LotProdListViewContainer userRoles={userRoles} />
                        </RouteGuard>
                      }
                    />
                    <Route
                      path={routes.lot.production.detail.url}
                      element={
                        <RouteGuard groupAllowedToView={LotProductionDetailPermissions.canVisit} userRoles={userRoles}>
                          <LotProdDetailContainer userRoles={userRoles} />
                        </RouteGuard>
                      }
                    />
                    <Route
                      path={routes.lot.production.boxStation.url}
                      element={
                        <RouteGuard
                          groupAllowedToView={LotProductionLabelStationPermissions.canVisit}
                          userRoles={userRoles}
                        >
                          <ProduceLot userRoles={userRoles} />
                        </RouteGuard>
                      }
                    />
                    <Route
                      path={routes.lot.production.labelStation.url}
                      element={
                        <RouteGuard
                          groupAllowedToView={LotProductionBoxStationPermissions.canVisit}
                          userRoles={userRoles}
                        >
                          <ProduceBox userRoles={userRoles} />
                        </RouteGuard>
                      }
                    />

                    {/** Udi */}
                    <Route
                      path={routes.udi.list.url}
                      element={
                        <RouteGuard groupAllowedToView={UdiListPermissions.canVisit} userRoles={userRoles}>
                          <UDIListViewContainer />
                        </RouteGuard>
                      }
                    />
                    <Route
                      path={routes.udi.detail.url}
                      element={
                        <RouteGuard groupAllowedToView={UdiListPermissions.canVisit} userRoles={userRoles}>
                          <UDIDetailView />
                        </RouteGuard>
                      }
                    />

                    {/** Warehouse */}
                    <Route
                      path={routes.warehouse.home.url}
                      element={
                        <RouteGuard groupAllowedToView={WarehouseListPermissions.canVisit} userRoles={userRoles}>
                          <WarehouseStartContainer />
                        </RouteGuard>
                      }
                    />
                    <Route
                      path={routes.warehouse.list.url}
                      element={
                        <RouteGuard groupAllowedToView={WarehouseListPermissions.canVisit} userRoles={userRoles}>
                          <WarehouseListContainer userRoles={userRoles} />
                        </RouteGuard>
                      }
                    />
                    <Route
                      path={routes.warehouse.stock.url}
                      element={
                        <RouteGuard
                          groupAllowedToView={WarehouseStockProductPermissions.canVisit}
                          userRoles={userRoles}
                        >
                          <WarehouseAddStockContainer />
                        </RouteGuard>
                      }
                    />
                    <Route
                      path={routes.warehouse.detail.url}
                      element={
                        <RouteGuard groupAllowedToView={WarehouseDetailPermissions.canVisit} userRoles={userRoles}>
                          <WarehouseDetailContainer />
                        </RouteGuard>
                      }
                    />

                    {/** Invoice */}
                    {/* <Route
                      path={routes.invoice.list.url}
                      element={
                        <RouteGuard groupAllowedToView={InvoiceListPermissions.canVisit} userRoles={userRoles}>
                          <InvoiceListViewContainer userRoles={userRoles} />
                        </RouteGuard>
                      }
                    />
                    <Route
                      path={`${routes.invoice.create.url}/*`}
                      element={
                        <RouteGuard groupAllowedToView={InvoiceCreatePermissions.canVisit} userRoles={userRoles}>
                          <InvoiceCreateContainer userRoles={userRoles} />
                        </RouteGuard>
                      }
                    /> */}
                    {/** Infection control */}
                    <Route
                      path={routes.infection.list.url}
                      element={
                        <RouteGuard groupAllowedToView={InfectionControlPermissions.canVisit} userRoles={userRoles}>
                          <InfectionControlContainer />
                        </RouteGuard>
                      }
                    />

                    <Route path={routes.dev.url} element={<UnderDevelopment />} />
                    <Route path="/prohibited" element={<Prohibited />} />
                  </Routes>
                </QueryClientProvider>
              </Suspense>
            </Box>
          </Box>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <h5 className="card-title">Please sign-in to see your profile information.</h5>
          <h5 className="card-title">You should be automatically redirected, if not please press Sign-In</h5>
          <DCButton label={"Sign-In"} onClick={handleClick} />
        </UnauthenticatedTemplate>
      </HashRouter>
    </ThemeProvider>
  );
};
render(
  <Auth>
    <Main />
  </Auth>,
  document.getElementById("root"),
);
