export const routes = {
  home: {
    url: "/",
    text: "Start",
  },
  product: {
    list: { url: "/products", text: "Products" },
    detail: { url: "/products/detail", text: "View Product" },
    edit: { url: "/products/detail/edit", text: "Edit Product" },
    create: { url: "/products/detail/create", text: "Create Product" },
  },
  lot: {
    management: {
      list: { url: "/lots/management", text: "LOT Management", parentUrl: "/" },
      detail: { url: "/lots/management/detail", text: "View LOT", parentUrl: "/lots/management" },
      edit: { url: "/lots/management/detail/edit", text: "Edit LOT", parentUrl: "/lots/management/detail" },
      create: { url: "/lots/management/detail/create", text: "Create LOT", parentUrl: "/lots/management" },
    },
    production: {
      list: { url: "/lots/production", text: "LOT Production", parentUrl: "/" },
      detail: { url: "/lots/production/detail", text: "View LOT" },
      boxStation: { url: "/lots/production/producelot", text: "Box Station" },
      labelStation: { url: "/lots/production/producelot/producebox", text: "Label Station" },
    },
  },
  udi: {
    list: { url: "/udi", text: "UDI Information" },
    detail: { url: "/udi/detail", text: "View UDI", parentUrl: "/udi" },
  },
  warehouse: {
    home: { url: "/warehouse", text: "Warehouse", parentUrl: "/" },
    list: { url: "/warehouse/list", text: "Warehouse", parentUrl: "/warehouse" },
    detail: { url: "/warehouse/detail", text: "View Stock", parentUrl: "/warehouse/list" },
    stock: { url: "/warehouse/stock", text: "Stock Product", parentUrl: "/warehouse/list" },
  },
  invoice: {
    list: { url: "/invoice", text: "Invoices", parentUrl: "/" },
    create: { url: "/invoice/create", text: "Create invoice", parentUrl: "/invoice" },
  },
  infection: {
    list: { url: "/infection", text: "Infection control", parentUrl: "/" },
  },
  dev: {
    url: "/underdev",
  },
};
