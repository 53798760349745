export const AllRoles = {
  CUSTOMER_SUPPORT: "60173d8c-e910-4f3d-be84-c7cf48bd70a2",
  LOT_EDITOR: "5e66c78a-44ed-48e3-ba5f-29d898a85c36",
  LOT_PREP_EDITOR: "73c88b92-6730-4d9e-950f-5d60251b8f0a",
  MANUFACTURING_EDITOR: "22ba3f5a-a653-40a3-a635-455f2b6d2304",
  MANUFACTURING_USER: "fd24a853-85e9-44e8-a379-e905f7a1fb5b",
  MANUFACTURING_ADMIN: "b765fb42-97c1-4257-aad7-42386881250f",
  PRODUCTION_ADMIN: "a8320371-8e3e-46fc-ab5f-0515453d32a3",
  PRODUCTION_USER: "3d06c568-9549-457b-8d65-cc00616bd340",
  PRODUCT_ADMIN: "e180c3b3-2e4f-4da2-b837-5e92639b75b1",
  PRODUCT_EDITOR: "1d2f9570-2985-4e3a-81f8-ee976a758fba",
  SUPER_ADMIN: "31fb4b67-fea1-46d4-bb67-6bf911642621",
  USER: "8d064de3-43b5-4c10-9b5c-534d616f640f",
  WAREHOUSE_EDITOR: "7f15192d-01df-4b94-89f2-011feadc91c8",
  INFECTION_CONTROL_EDITOR: "b55a1129-89bc-4d90-abf8-807de7692cf0",
  FINANCIAL_ADMIN: "bbeb26ca-90ea-425c-a403-58b420a33e90",
  DEV: "DEV", // For storybook
};

export type Roles = string[];
type AllRoles = typeof AllRoles;
type KeyOfRoles = keyof AllRoles;

export enum Permissions {
  CREATE,
  READ,
  UPDATE,
  DELETE,
}

export type MapPermissionToRole<RoleList> = {
  [Key in keyof RoleList]: Permissions[];
};

export type RolePermissions<RoleList, Ui> = {
  canVisit: Roles;
  ui: Ui;
  permissions: MapPermissionToRole<RoleList>;
};

export const CRUD = [Permissions.READ, Permissions.CREATE, Permissions.UPDATE, Permissions.DELETE];
export const READ_UPDATE = [Permissions.READ, Permissions.UPDATE];
export const READ = [Permissions.READ];

export const convertToRoleValueList = (list: { [Key in keyof Partial<AllRoles>]: string }): Roles =>
  Object.values(list).map((value) => value);

export const canUserVisit = (groupAllowedToView: Roles, userRoles: Roles): boolean => {
  const intersection = groupAllowedToView.filter((group: string) => userRoles.includes(group));
  return intersection.length > 0;
};

export const getRoleByValue = (value: string): string | undefined =>
  Object.keys(AllRoles).find((key) => AllRoles[key as KeyOfRoles] === value);
