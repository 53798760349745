import React, { FC } from "react";
import { Navigate } from "react-router-dom";
import { AllRoles, canUserVisit } from "src/utils/route-guard/permissions";
import { Roles } from "src/utils/route-guard/permissions";

interface IRouteGuard {
  groupAllowedToView: Roles;
  userRoles: Roles;
}

export const RouteGuard: FC<IRouteGuard> = ({ groupAllowedToView, userRoles, children }) => {
  return canUserVisit(groupAllowedToView, userRoles) || userRoles.includes(AllRoles.DEV) ? (
    <>{children}</>
  ) : (
    <Navigate to="/prohibited" />
  );
};
